import { UncontrolledCarousel } from 'reactstrap'
import sliderImage1 from './assets/img/footer-logo.png'
import sliderImage2 from './assets/images/slider/04.jpg'
import sliderImage3 from './assets/images/slider/01.jpg'
import bgslider from './assets/img/bg.avif';

const images = [
    {
        src: bgslider,
        id: 1,
        altText: 'Slide 1',
    },
    {
        src: sliderImage2,
        id: 2,
        altText: 'Slide 2',
    },
    {
        src: sliderImage3,
        id: 3,
        altText: 'Slide 3',
    },
    {
        src: sliderImage1,
        id: 4,
        altText: 'Slide 5',
    }
]

const CarouselInterval = () => {
    return <UncontrolledCarousel items={images} interval='6000' />
}
export default CarouselInterval