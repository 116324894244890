import decorationLeft from './assets/images/elements/decore-left.png'
import decorationRight from './assets/images/elements/decore-right.png'
import { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, CardTitle, CardBody, CardText, Container } from "reactstrap";


import bgslider from './assets/img/bg.avif';
import qatar from './assets/flags/qatar.webp';
import instance from "./api_instance"




const Groups = (props) => {


    const { day } = props

    const [data, setData] = useState();
    const [isLoading, setLoading] = useState(true);


    console.log(day);

    useEffect(() => {
        const getData = async () => {
            const arr = [];
            await instance({
                url: "/getStandings",
                method: "GET"
            }).then((res) => {
                if (res.status === 200) {
                    // MySwal.close();

                }
                setLoading(false);

                console.log(res);
                setData(res.data.data)
            })

        };
        getData();
    }, []);



    const renderRenderList = () => {
        if (!isLoading) {

            return data.map(item => {
                return (


                    <Col xs="6">

                        <section section id="bottomLeft_section" className="bottomLeft_section container" >
                            <h1 className="w-100 text-right"> المجموعة ( {item.group} )  :</h1>
                            <div id="matchesScheduleContainer" className="matchesScheduleContainer">

                                {
                                    item.teams.map(tem => {
                                        return (
                                            <div id="match_slice1 " className="match_slice border border-secondary mt-2">

                                                <div className="slice_middle">
                                                    <div className="left" dir='ltr'>
                                                        <h3>{


                                                            tem.name_fa

                                                        } </h3>
                                                        <picture>
                                                            <source srcSet={tem.flag} type="image/webp" />
                                                            <img className='rounded-pill' src={tem.flag} alt="" draggable="false" />
                                                        </picture>
                                                    </div>

                                                </div>
                                                <div className="slice_right">
                                                    <p>
                                                        {tem.pts}
                                                    </p>
                                                </div>

                                            </div>

                                        )
                                    })
                                }



                            </div>
                        </section >
                    </Col>



                );
            })

        }
    };









    return (
        <Fragment>

            <div className=" ml-2 mt-2">
                <div className="col">
                    <div className="list">

                        {

                            data !== null ? (
                                <div className="key">
                                    <Row>{renderRenderList()}</Row>
                                </div>
                            ) : null}
                    </div>
                </div>
            </div>

        </Fragment>
    );



}

export default Groups
