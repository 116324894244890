import logo from './logo.svg';
import './App.css';
import CarouselInterval from './CarouselInterval';
import { Container, Row, Col, Badge } from 'reactstrap';
import CardCongratulations from './CardCongratulations'
import Groupsss from './groups'
import Teble from './Teble'
import Navbar from './Navbar'


// import instance from "../src/views/api/api_instance";
import { useState, useEffect } from "react";
import axios from "axios";

import bgslider from './assets/img/bg.avif';
import qatar from './assets/flags/qatar.webp';


function App() {




  return (
    <div className="App">

      <div className="main_section">
        <section id="left_section" class="left_section sidebar">
          <div id="selector" class="home"></div>
        </section>
        <section id="left_section" className="left_section">
          <section id="topLeft_section" className="topLeft_section">
            <div className="slider swiper">
              <CarouselInterval></CarouselInterval>

              <div id="slider_wrapper" className="swiper-wrapper">


                <div id="slide1" className="slide swiper-slide p-3">

                  <picture>
                    <source srcSet={bgslider} type="image/avif" />
                    <source srcSet="./assets/img/bg.webp" type="image/webp" />
                    <img src="./assets/img/bg.png" alt="" className="bg" />
                  </picture>
                  <div className="slide_left_content">
                    <h2>
                      كاس العالم 2022

                      <br />ميديا ماكس تي في™
                    </h2>
                    <p>
                      اي شي واي حاجه
                      اي شي واي حاجه
                      اي شي واي حاجه
                      اي شي واي حاجه
                      اي شي واي حاجه
                      اي شي واي حاجه
                      اي شي واي حاجه
                      اي شي واي حاجه
                      اي شي واي حاجه
                      اي شي واي حاجه
                      اي شي واي حاجه
                      اي شي واي حاجه
                      اي شي واي حاجه
                      اي شي واي حاجه
                    </p>
                    <a href="#" draggable="false">
                      زور موقعنا
                    </a>
                  </div>
                  <div className="slide_right_content"></div>

                </div>

                <div id="slide3" className="slide swiper-slide">
                  <picture>
                    <source srcSet="./assets/img/bg.avif" type="image/avif" />
                    <source srcSet={qatar} type="image/webp" />
                    <img src="./assets/img/bg.png" alt="" className="bg" />
                  </picture>
                  <div className="slide_left_content">
                    <h2>Slide 3</h2>
                    <p>This is the discription of the slide</p>
                    <a href="#" draggable="false">
                      Read more
                    </a>
                  </div>
                  <div className="slide_right_content" />
                </div>
              </div>

            </div>
            <div className="pagination swiper-pagination" />
          </section>
          {/* {renderRenderList()} */}

          <CardCongratulations day="1" />
          <Container>


            <div className="slice_right">

              <Badge color="danger">now start</Badge>

            </div>


            <Row>



              <Groupsss day="4" />

            </Row>

          </Container>
          <Container>


            <div className="slice_right">

            </div>


            <Row>



              <Teble day="4" />

            </Row>

          </Container>
        </section>




      </div>





    </div>
  );
}



export default App;
