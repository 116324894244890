import axios from "axios";


// const token = localStorage.getItem('accessToken')

// axios.defaults.headers.common = { 'Authorization': `Bearer ${JSON.parse(token)}` }

const instance = axios.create({

    baseURL: 'https://m-t-api.mediamaxtv.com/api/',
    // headers: {
    //     'Content-Type': 'application/json',
    //     'accept': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    //     timeout: 1000,
    // },



});



export default instance;
