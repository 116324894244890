import decorationLeft from './assets/images/elements/decore-left.png'
import decorationRight from './assets/images/elements/decore-right.png'
import { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, CardTitle, CardBody, CardText } from "reactstrap";


import bgslider from './assets/img/bg.avif';
import qatar from './assets/flags/qatar.webp';
import instance from "./api_instance"




const CardCongratulations = (props) => {


    const { day } = props

    const [data, setData] = useState();
    const [isLoading, setLoading] = useState(true);


    console.log(day);

    useEffect(() => {
        const getData = async () => {
            const arr = [];
            await instance({
                url: "/getbymatch/" + day,
                method: "GET"
            }).then((res) => {
                if (res.status === 200) {
                    // MySwal.close();

                }
                setLoading(false);

                console.log(res);
                setData(res.data.data)
            })

        };
        getData();
    }, []);



    const renderRenderList = () => {
        if (!isLoading) {


            return (
                <Col md="13" className="list">
                    <section section id="bottomLeft_section" className="bottomLeft_section container" >
                        <h1 className="w-100 text-right"> مباراة اليوم :</h1>
                        <div id="matchesScheduleContainer" className="matchesScheduleContainer">
                            <div id="match_slice1 " className="match_slice">
                                <div className="slice_left">

                                    <p>

                                        {data[0].home_score}
                                    </p>
                                </div>
                                <div className="slice_middle">
                                    <div className="left">
                                        <h3> {data[0].home_team_fa}</h3>
                                        <picture>
                                            <source srcSet={data[0].home_flagar} type="image/webp" />
                                            <img src={data[0].home_flag} alt="" draggable="false" />
                                        </picture>
                                    </div>
                                    <div className="middle">
                                        <span>VS</span>
                                        <span>ضد</span>
                                    </div>
                                    <div className="right">
                                        <picture>
                                            <source srcSet={data[0].away_flag} type="image/webp" />
                                            <img src={data[0].away_flag} alt="" draggable="false" />
                                        </picture>
                                        <h3>{data[0].away_team_fa}</h3>
                                    </div>
                                </div>
                                <div className="slice_right">
                                    <p>
                                        {data[0].away_score}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section >
                </Col>
            );
        }
    };









    return (
        <Fragment>

            <div className=" ml-2 mt-2">
                <div className="col">
                    <div className="list">

                        {

                            data !== null ? (
                                <div className="key">
                                    <Row>{renderRenderList()}</Row>
                                </div>
                            ) : null}
                    </div>
                </div>
            </div>

        </Fragment>
    );



}

export default CardCongratulations
